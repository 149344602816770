.PromoImg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
}

@media screen and (max-width: 500px) {
    .PromoImg {
        height: 300px;
    }
}