@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Baloo 2', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #9eaabf;
  min-width: 320px;
  color: #132539;
}

html {
  --bright-bg-color: #bfc3cb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: collapse;
}

table th {
  background-color: var(--bright-bg-color);
  border-color: #bfc3cb;
  padding: 10px;
}

table td {
  text-align: center;
}

