.Button {
    background: rgb(194,146,155);
    background: linear-gradient(180deg, rgba(194,146,155,1) 88.9%, rgba(194,146,155,1) 89%, rgba(185,127,139,1) 89.1%);
    border: none;
    font-size: 20px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    color: #f1f1f1;
    padding-left: 60px;
    padding-right: 60px;
    margin: 5px 10px 10px 5px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
}

.Button:active {
    background: red;
    color: #f1f1f1;
    background: linear-gradient(180deg, rgb(161, 115, 124) 89%, rgb(151, 104, 113) 88.9%);
}

.Button:hover {
    color: white;
}