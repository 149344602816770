@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: 'Baloo 2', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #9eaabf;
  min-width: 320px;
  color: #132539;
}

html {
  --bright-bg-color: #bfc3cb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: collapse;
}

table th {
  background-color: #bfc3cb;
  background-color: var(--bright-bg-color);
  border-color: #bfc3cb;
  padding: 10px;
}

table td {
  text-align: center;
}


.App_App__296tj {
    margin-left: 10%;
    margin-right: 10%;
}

.App_Description__1m8f9 {

}

.App_ButtonsSection__WQc2c {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    max-width: 300px;
}

.App_ButtonSectionWrapper__1sMYJ {
    display: flex;
    align-items: center;
    justify-content: center;
}


/*@media screen and (max-width: 600px) {*/
/*    .ButtonsSection {*/
/*        flex-direction: column;*/
/*    }*/
/*}*/
.Title_Title__3uqeV {
    color: #dfe0e3;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .Title_Title__3uqeV {
        font-size: 26px;
    }

    .Title_Title__3uqeV img {
        width: 100px;
        height: 100px;
    }
}

@media screen and (min-width: 501px) {
    .Title_Title__3uqeV {
        font-size: 50px;
    }

    .Title_Title__3uqeV img {
        width: 200px;
        height: 200px;
    }
}
.Button_Button__1D8Ez {
    background: rgb(194,146,155);
    background: linear-gradient(180deg, rgba(194,146,155,1) 88.9%, rgba(194,146,155,1) 89%, rgba(185,127,139,1) 89.1%);
    border: none;
    font-size: 20px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    color: #f1f1f1;
    padding-left: 60px;
    padding-right: 60px;
    margin: 5px 10px 10px 5px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
}

.Button_Button__1D8Ez:active {
    background: red;
    color: #f1f1f1;
    background: linear-gradient(180deg, rgb(161, 115, 124) 89%, rgb(151, 104, 113) 88.9%);
}

.Button_Button__1D8Ez:hover {
    color: white;
}
.PromoImg_PromoImg__1nnZl {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
}

@media screen and (max-width: 500px) {
    .PromoImg_PromoImg__1nnZl {
        height: 300px;
    }
}
div.pages_center__3Fl8y {
    display: flex;
    justify-content: center;
}
