.Title {
    color: #dfe0e3;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .Title {
        font-size: 26px;
    }

    .Title img {
        width: 100px;
        height: 100px;
    }
}

@media screen and (min-width: 501px) {
    .Title {
        font-size: 50px;
    }

    .Title img {
        width: 200px;
        height: 200px;
    }
}