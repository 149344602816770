.App {
    margin-left: 10%;
    margin-right: 10%;
}

.Description {

}

.ButtonsSection {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    max-width: 300px;
}

.ButtonSectionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}


/*@media screen and (max-width: 600px) {*/
/*    .ButtonsSection {*/
/*        flex-direction: column;*/
/*    }*/
/*}*/